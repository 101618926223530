import { PageTitle, Subtitle1 } from "../../../../brand/typographies";
import { Input } from "../../../../components/Input/Input";
import { StorSelfButton } from "../../../../components/buttons/StorSelfButton";
import * as yup from "yup";
import { useFormImplementation } from "../../../../hooks/useFormImplementation";
import { useStoreSelfNavigation } from "../../../../hooks/useStorSelfNavigation";
import { auctionBuyerRoutes, mainRoutes } from "../../../../router/routes";
import { Section } from "../../../../components/layout/Section";
import { StringParam, useQueryParam } from "use-query-params";

export function AuctionBuyerMoveoutOverviewPage() {
    const navigate = useStoreSelfNavigation()

    const [firstName] = useQueryParam("firstName", StringParam)
    const [lastName] = useQueryParam("lastName", StringParam)
    const [unitName] = useQueryParam("unitName", StringParam)
    const [receiptId] = useQueryParam("receiptId", StringParam)

    const { fieldProps, handleSubmit } = useFormImplementation<{ firstName: string, lastName: string, unitName: string, receiptId: string }>(
        schema,
        Object.keys({ ...schema.fields }),
        firstName && lastName && unitName ?
            {
                firstName: firstName || "",
                lastName: lastName || "",
                unitName: unitName || "",
                receiptId: receiptId || "",
            } : undefined
    );

    const handleContinue = (formValues: any) => {
        navigate(mainRoutes.AuctionBuyer + auctionBuyerRoutes.MoveoutTakePhoto + "?" + new URLSearchParams(formValues).toString())
    }

    return (
        <>
            <Section>
                <PageTitle>Finalize Auction</PageTitle>
                <Input
                    fullWidth
                    label="First Name"
                    {...fieldProps.firstName}
                />

                <Input
                    fullWidth
                    label="Last Name"
                    {...fieldProps.lastName}
                />

                <Input
                    fullWidth
                    label="Unit Name"
                    {...fieldProps.unitName}
                />

                <Input
                    fullWidth
                    label="Receipt ID"
                    placeholder="Optional"
                    {...fieldProps.receiptId}
                />
            </Section>

            <Subtitle1 sx={{ my: 1 }} center>
                Take a photo of clean unit to finalize.
            </Subtitle1>


            <StorSelfButton
                analyticsTag="Open Camera"
                onClick={handleSubmit(handleContinue)}>Take Photo</StorSelfButton>
        </>
    );
};

const schema = yup.object().shape({
    firstName: yup.string().label("First Name").required(),
    lastName: yup.string().label("Last Name").required(),
    unitName: yup.string().label("Unit Name").required(),
    receiptId: yup.string().label("Receipt ID").default(""),
}).required();
