import { SvgIconContainer } from "./SvgIconContainer";


export const HomeIcon = () => (
    <SvgIconContainer>
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_189_2236)">
                <path d="M11.86 3.47998C11.95 3.47998 12.05 3.47998 12.14 3.47998C12.31 3.58998 12.48 3.68998 12.64 3.79998C13.15 4.14998 13.66 4.50998 14.18 4.85998C16.34 6.34998 18.51 7.83998 20.67 9.32998C20.89 9.47998 21.02 9.65998 21.02 9.93998C21.02 13.53 21.02 17.12 21.02 20.72C21.02 21.2 20.71 21.52 20.23 21.52C19.79 21.52 19.35 21.52 18.91 21.52C18.87 21.52 18.82 21.52 18.77 21.51V21.29C18.77 18.4 18.77 15.51 18.77 12.63C18.77 12.05 18.48 11.76 17.91 11.76C13.98 11.76 10.04 11.76 6.11 11.76C5.53 11.76 5.25 12.05 5.25 12.63C5.25 15.52 5.25 18.42 5.25 21.31V21.53C4.8 21.53 4.36 21.52 3.93 21.53C3.47 21.55 3.15 21.37 3 20.93C3 17.2 3 13.46 3 9.72998C3.14 9.58998 3.26 9.41998 3.42 9.29998C5.53 7.82998 7.65 6.37998 9.77 4.91998C10.47 4.43998 11.17 3.96998 11.87 3.48998L11.86 3.47998Z" fill="#2F2E2E" />
                <path d="M11.44 13.25C11.44 13.45 11.44 13.6301 11.44 13.8001C11.44 14.1301 11.68 14.37 12 14.37C12.32 14.37 12.56 14.1301 12.56 13.8001C12.56 13.6201 12.56 13.45 12.56 13.25C12.77 13.25 12.98 13.25 13.18 13.25C13.39 13.25 13.6 13.25 13.81 13.25C14.1 13.25 14.25 13.4001 14.25 13.6801C14.25 14.6401 14.25 15.61 14.25 16.57C14.25 16.86 14.11 17.0101 13.82 17.0101C12.61 17.0101 11.39 17.0101 10.18 17.0101C9.89 17.0101 9.75 16.87 9.75 16.57C9.75 15.61 9.75 14.6401 9.75 13.6801C9.75 13.4201 9.88 13.26 10.11 13.25C10.49 13.24 10.86 13.25 11.24 13.25C11.3 13.25 11.36 13.25 11.44 13.25Z" fill="#2F2E2E" />
                <path d="M14.0699 17.7502C14.0699 17.9502 14.0699 18.1202 14.0699 18.2902C14.0699 18.6302 14.3099 18.8702 14.6199 18.8702C14.9399 18.8702 15.1799 18.6402 15.1899 18.3002C15.1899 18.1202 15.1899 17.9402 15.1899 17.7502C15.6599 17.7502 16.0999 17.7402 16.5399 17.7502C16.7599 17.7502 16.8699 17.9302 16.8799 18.1902C16.8799 18.7102 16.8799 19.2302 16.8799 19.7602C16.8799 20.1902 16.8799 20.6202 16.8799 21.0402C16.8799 21.3602 16.7399 21.5002 16.4199 21.5002C15.2199 21.5002 14.0299 21.5002 12.8299 21.5002C12.5099 21.5002 12.3799 21.3702 12.3799 21.0502C12.3799 20.0902 12.3799 19.1402 12.3799 18.1802C12.3799 17.8802 12.5199 17.7402 12.8199 17.7402C13.2299 17.7402 13.6399 17.7402 14.0699 17.7402V17.7502Z" fill="#2F2E2E" />
                <path d="M8.79986 17.7598C8.79986 17.9498 8.79986 18.1098 8.79986 18.2698C8.79986 18.6298 9.03986 18.8798 9.36986 18.8798C9.69986 18.8798 9.91986 18.6298 9.92986 18.2698C9.92986 18.1098 9.92986 17.9398 9.92986 17.7598C10.1899 17.7598 10.4199 17.7598 10.6599 17.7598C10.8499 17.7598 11.0399 17.7598 11.2199 17.7598C11.4699 17.7698 11.6099 17.9098 11.6099 18.1598C11.6099 19.1498 11.6099 20.1298 11.6099 21.1198C11.6099 21.3798 11.4599 21.5198 11.1999 21.5198C9.96986 21.5198 8.74986 21.5198 7.51986 21.5198C7.25986 21.5198 7.10986 21.3798 7.10986 21.1198C7.10986 20.1298 7.10986 19.1498 7.10986 18.1598C7.10986 17.9298 7.23986 17.7698 7.45986 17.7698C7.89986 17.7598 8.33986 17.7698 8.79986 17.7698V17.7598Z" fill="#2F2E2E" />
            </g>
            <defs>
                <clipPath id="clip0_189_2236">
                    <rect width="24" height="24" fill="white" transform="translate(0 0.5)" />
                </clipPath>
            </defs>
        </svg>
    </SvgIconContainer>
);
