import { SvgIconContainer } from "./SvgIconContainer";


export const ChatIcon = () => (
    <SvgIconContainer>
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_189_2248)">
                <path d="M19.6001 21.93C19.2201 21.88 18.8401 21.83 18.4601 21.76C17.6601 21.61 16.9001 21.35 16.1601 21C16.0401 20.95 15.8901 20.92 15.7601 20.94C13.8701 21.22 12.0701 20.97 10.3901 20.04C9.49014 19.54 8.73014 18.87 8.14014 17.98C8.29014 17.92 8.45014 17.88 8.58014 17.79C8.96014 17.55 9.35014 17.56 9.78014 17.61C10.5601 17.71 11.3501 17.69 12.1301 17.6C13.4601 17.45 14.7201 17.08 15.9001 16.44C17.6101 15.52 18.9301 14.23 19.7201 12.43C19.8101 12.22 19.8801 12 19.9701 11.77C20.3501 12.15 20.6401 12.57 20.8801 13.03C21.1801 13.59 21.3601 14.19 21.4301 14.83C21.4301 14.87 21.4501 14.9 21.4601 14.94V15.6C21.4101 15.91 21.3701 16.22 21.2901 16.52C21.0001 17.6 20.4001 18.48 19.5701 19.21C19.4801 19.29 19.4001 19.36 19.3101 19.44C19.7301 20.02 20.2201 20.48 20.9101 20.68C21.1801 20.76 21.3801 20.91 21.4601 21.19V21.41C21.3401 21.74 21.1001 21.89 20.7501 21.89C20.6901 21.89 20.6301 21.91 20.5701 21.92H19.6101L19.6001 21.93Z" fill="#2F2E2E" />
                <path d="M5.16993 14.7498C4.73993 14.3098 4.29993 13.9098 3.92993 13.4598C2.99993 12.3298 2.53993 11.0198 2.59993 9.54978C2.64993 8.44978 3.00993 7.43978 3.64993 6.53978C4.76993 4.95978 6.30993 3.99978 8.14993 3.47978C9.45993 3.09978 10.7999 3.01978 12.1499 3.19978C13.5299 3.37978 14.8099 3.81978 15.9699 4.57978C17.3999 5.51978 18.4599 6.74978 18.8999 8.43978C19.3299 10.1298 18.9999 11.6998 17.9999 13.1198C16.8899 14.6898 15.3399 15.6398 13.5099 16.1598C12.0599 16.5698 10.5899 16.6298 9.10993 16.3798C9.00993 16.3598 8.88993 16.3798 8.79993 16.4198C7.45993 17.0898 6.03993 17.5298 4.55993 17.7298C4.10993 17.7898 3.65993 17.8098 3.19993 17.8198C2.89993 17.8198 2.64993 17.5898 2.60993 17.3098C2.56993 17.0398 2.72993 16.7598 3.00993 16.6798C3.78993 16.4498 4.32993 15.9098 4.80993 15.2898C4.94993 15.1098 5.06993 14.9198 5.17993 14.7598L5.16993 14.7498Z" fill="#2F2E2E" />
            </g>
            <defs>
                <clipPath id="clip0_189_2248">
                    <rect width="24" height="24" fill="white" transform="translate(0 0.5)" />
                </clipPath>
            </defs>
        </svg>

    </SvgIconContainer>
);
