import { PaymentIcon } from "../../icons";
import { StorSelfIconButton } from "../StorSelfIconButton";
import { usePaymentNavigate } from "../../../pages/payments/[hooks]/usePaymentNavigation";

/**
 * Renders a button component for navigating to the payments page.
 * @returns The rendered PaymentsButton component.
 */
export const PaymentsButton = () => {
    const { pending, navigateToPayments } = usePaymentNavigate();

    return (
        <StorSelfIconButton
            pending={pending}
            Icon={PaymentIcon}
            text="Payments"
            analyticsTag={"To Payments"}
            onClick={navigateToPayments} />
    );
};
