import delinquent from "./delinquent";
import newMoveIn from "./newMoveIn";

export const payDelinquent = async () => {
    delinquent.ledger.delinquent = false;
    delinquent.ledger.facilityUnit.balance = 0;
    delinquent.ledger.balance = 0;
    delinquent.ledger.paidThrough = newMoveIn.ledger.paidThrough;
    delinquent.ledger.nextChargeDate = newMoveIn.ledger.nextChargeDate;
};
