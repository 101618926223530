import { createContext } from "react";
import { IFacility } from "../../types/IFacility";
import { LatLongResult } from "../../hooks/useLatLong";

export const FacilitiesContext = createContext<IFacilitiesContext>({
    setSelectedFacility: () => { },
    selectedFacility: null,
    facilities: [],
    locationError: "",
    atFacility: false,
    fetchingFacilities: false,
    currentPosition: { loading: true } as LatLongResult,
    closestFacility: null,
});

interface IFacilitiesContext {
    setSelectedFacility: any,
    selectedFacility: IFacility | null,
    facilities: IFacility[],
    locationError: string,
    atFacility: boolean,
    fetchingFacilities: boolean,
    currentPosition: LatLongResult,
    closestFacility: IFacility | null,
};
