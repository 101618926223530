import { isMobile } from "react-device-detect";
import { FacilityAddress } from "../../components/FacilityAddress";
import { PageTitle } from "../../brand/typographies";
import { Section } from "../../components/layout/Section";
import { companyConfig } from "../../globalContext";
import { SwivlChatButton } from "../../components/buttons/NavigationButtons/SwivlChatButton";
import { EmailUsButton } from "../../components/buttons/NavigationButtons/EmailUsButton";

export const ExistingCustomerContactUsPage = () => {
    return (
        <>
            <PageTitle>Contact Us</PageTitle>
            <FacilityAddress />
            <Section sx={{ my: 4, px: 4, boxSizing: "border-box", }}>
                <EmailUsButton />

                {
                    isMobile
                    && companyConfig.swivlId
                    && <SwivlChatButton />
                }
            </Section>
        </>
    );
};
