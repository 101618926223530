import { useState } from "react";
import { getOrdinalNum } from "../../../util";
import { LabeledCheckBox } from "./LabeledCheckBox";
import { ILedger } from "../../../types/ILedger";

export const useAutopay = (ledger?: ILedger | null) => {
    const [selectedDay, setSelectedDay] = useState<number>(0);
    const [autopayEnabled, setAutopayEnabled] = useState(true);
    const ordinalDay = getOrdinalNum(ledger?.invoiceDayOfMonth || selectedDay);

    const handleCheck = () => {
        setSelectedDay(ledger?.invoiceDayOfMonth || 1)
        setAutopayEnabled(true)
    };

    return {
        selectedDay,
        ordinalDay,
        autopayEnabled,
        AutopayCheckbox: () =>
            <LabeledCheckBox
                checked={autopayEnabled}
                handleCheck={handleCheck}
                setChecked={setAutopayEnabled}
                label="Autopay"
            />
    };
};

export const useSaveCard = (isChecked: boolean) => {
    const [saveCard, setSaveCard] = useState(false);

    return {
        saveCard: saveCard || isChecked,
        SaveCardCheckBox: () =>
            <LabeledCheckBox
                checked={saveCard || isChecked}
                handleCheck={() => setSaveCard(true)}
                setChecked={setSaveCard}
                label="Save Card"
            />
    };
};
