// import { config } from "../../config/companyConfig";

import { companyConfig } from "../globalContext";

export class Colors {
    static charcoal = () => "#36454F"
    static black = (opacity: number = 1) => `rgba(0, 0, 0, ${opacity ? opacity : 1})`;
    static white = () => "#FCFCFC";
    static navy = () => "#0e1111";
    static primary = () => companyConfig.primaryColor;
    static redHover = () => "#AB2125";
    static redText = () => "#811216";
    static yellow = () => "#FEE000";
    static yellowHover = () => "#F5D800";
    static blue = () => "#007bff";
    static lightgrey = () => "#757575";
    static lightergrey = () => "#ced4da";
    static grey = () => "#78909C";
    static greyHover = () => "#536d7b";
    static tableGrey = () => "#E9EAEC";
    static green = () => '#5cb85c';
    static infoBlue = () => '#5bc0de';
    static warningYellow = () => '#f0ad4e';
    static pink = () => '#E6D1D2';
}
