
export const getTenantAuth = () => _context.tenantAuth;
export const setTenantAuth = (phoneNumber: string) => _context.tenantAuth = {
    phoneNumber
}

export const kioskFacilityId = getSearchParam("kioskFacId")
// export const paymentFacilityId = getSearchParam("paymentFacId")

if (_context.companyConfig.stagingPassword) {
    _context.companyConfig.subdomain = "demo"
}

export const companyConfig = _context.companyConfig;
export const logoBase64String = _context.base64String;
export const environment = _context.environment;

function getSearchParam(key: string): string | null {
    // Create a URL object using the current window location
    const url = new URL(window.location.href);

    // Use URLSearchParams to parse query parameters
    const queryParams = new URLSearchParams(url.search);

    // Get 'kioskFacId' parameter from the query string
    return queryParams.get(key);
}
