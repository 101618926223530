import { Box } from "@mui/material";

import { companyConfig } from "../../globalContext";
import { FacilityAddress } from "../../components/FacilityAddress";
import { FlexBox } from "../../components/layout/FlexBox";
import { auctionBuyerRoutes, mainRoutes } from "../../router/routes";
import { MoveoutIcon } from "../../components/icons";
import { PageTitle, Subtitle1, Subtitle2 } from "../../brand/typographies";
import { startChat } from "../../startChat";
import { StorSelfIconButton } from "../../components/buttons/StorSelfIconButton";
import { useStoreSelfNavigation } from "../../hooks/useStorSelfNavigation";
import useAnalytics from "../../hooks/useAnalytics";

export const AuctionBuyerPage = () => {
    return (
        <>
            <PageTitle>Auction Buyers</PageTitle>

            <FacilityAddress />

            <FlexBox sx={{ my: 4 }}>
                <StorSelfIconButton
                    fullWidth
                    sx={{
                        gridColumn: "1/-1",
                    }}
                    Icon={MoveoutIcon}
                    text="Finalize Move Out"
                    analyticsTag="To Auction Buyer Move Out"
                    to={mainRoutes.AuctionBuyer + auctionBuyerRoutes.MoveoutOverview}
                />
            </FlexBox>

            <AuctionBuyerChat />
        </>
    );
};

const AuctionBuyerChat = () => {
    const navigate = useStoreSelfNavigation();
    const { trackClickEvent } = useAnalytics("AuctionBuyerChat")

    return (
        <Box
            mt={2}
            display={'flex'}
            width={'100%'}
            flexDirection={"column"}
            justifyContent={'center'}
            alignItems={'center'}>

            <Subtitle1 center>Problems Accessing Property?</Subtitle1>
            <Subtitle2 center onClick={() => {
                trackClickEvent("Open Chat")
                if (companyConfig.swivlId) {
                    startChat();
                } else {
                    navigate(mainRoutes.ContactUs)
                }
            }} bold brandColor>Click Here</Subtitle2>
        </Box>
    );
};
