import { MoveoutIcon } from "../../icons";
import { StorSelfIconButton } from "../StorSelfIconButton";
import { useMoveoutEntryNavigate } from "../../../pages/moveout/[hooks]/useMoveoutEntryNavigation";
import { analyticsService } from "../../../services/analytics-service";

/**
 * Renders a button component for moving out.
 * @returns The MoveOutButton component.
 */
export const MoveOutButton = () => {
    const { pending, navigateToMoveout } = useMoveoutEntryNavigate();

    return (
        <StorSelfIconButton
            pending={pending}
            Icon={MoveoutIcon}
            onClick={() => {
                analyticsService.trackClickEvent("Existing Customers", "To Move Out", null);
                navigateToMoveout();
            }}
            text="Move out" />
    );
};
