export const mainRoutes = {
    Landing: "/",
    NewCustomer: "/new-customer",
    ContactUs: "/contact-us",
    ExistingCustomer: "/existing-customer",
    Access: "/access/*",
    Payments: "/payments/*",
    Account: "/account",
    MoveOut: "/moveout/*",
    AuctionBuyer: "/auction-buyer"
}
export const paymentRoutes = {
    PaymentAccountLookup: "/lookup",
    PaymentNoUnits: "/no-units-found",
    PaymentSelectUnit: "/select-unit",
    PaymentAmount: "/payment-amount",
    PaymentMethod: "/payment-method",
    PaymentConfirm: "/confirm-payment",
    PaymentNewMethod: "/payment-method/new",
    PaymentForm: "/payment-form"
}

export const moveoutRoutes = {
    MoveoutAccountLookup: "/lookup",
    MoveoutSelectUnit: "/select-unit",
    MoveoutOverview: "/overview",
    MoveoutNoUnits: "/no-units-found",
    MoveoutTakePhoto: "/take-photo",
    MoveoutConfirm: "/confirm-moveout"
}

export const accessRoutes = {
    AccessFacility: "/",
    AccessPoints: "/access-points",
    OpenAccessPoint: "/access-points/open/:id",
    AccessUnit: "/unit"
}

export const auctionBuyerRoutes = {
    MoveoutOverview: "/move-out/overview",
    MoveoutTakePhoto: "/move-out/take-photo",
    MoveoutConfirm: "/move-out/confirm"
}
