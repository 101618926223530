import { useEffect, useRef, useState } from "react";

export type LatLongResult = {
    latitude: number,
    longitude: number,
    loading: boolean,
    error: GeolocationPositionError | null
}

export const useLatLong = () => {
    const updateInterval: number = 1000 * 60;
    const [currentPosition, setCurrentPosition] = useState<LatLongResult>({
        latitude: 0,
        longitude: 0,
        loading: true,
        error: null
    });
    const intervalRef = useRef<number | null>(null);

    const updatePosition = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (res: GeolocationPosition) => {
                    setCurrentPosition({
                        latitude: res.coords.latitude,
                        longitude: res.coords.longitude,
                        loading: false,
                        error: null
                    });
                }, (err: GeolocationPositionError) => {
                    setCurrentPosition(prevState => ({
                        ...prevState,
                        error: err
                    }));
                    intervalRef.current && clearInterval(intervalRef.current)
                }, {
                enableHighAccuracy: false,
                timeout: 15000,
                maximumAge: 0
            });
        }
    };

    useEffect(() => {
        updatePosition(); // Update position immediately on mount
        intervalRef.current = setInterval(updatePosition, updateInterval); // Update position every x milliseconds

        return () => {
            intervalRef.current && clearInterval(intervalRef.current); // Clear interval on unmount
        }
    }, [updateInterval])

    return currentPosition;
}
