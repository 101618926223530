import styled from '@emotion/styled';
import { useEffect, useRef, useState } from 'react';
import { Box } from '@mui/material';
import { Camera, CameraType } from '.';
import { H6, PageTitle, Subtitle1 } from '../../brand/typographies';
import useAnalytics from '../../hooks/useAnalytics';

type CameraProps = {
    navigateNext: () => void;
    navigateBack: () => void;
    imageKey?: string;
    title?: string;
    instructions?: string;
}

export function CameraView({
    navigateNext,
    navigateBack,
    imageKey = "image",
    title = "Move out",
    instructions = "Take a photo of empty unit. Make sure to get the unit number in the photo.",
}: CameraProps) {
    const [image, setImage] = useState<string | null>(null);
    const [showImage, setShowImage] = useState<boolean>(false);
    const camera = useRef<CameraType>(null);
    const [pressed, setPressed] = useState(false)
    const [cameraReady, setCameraReady] = useState(false)

    const { trackClickEvent } = useAnalytics("Camera")

    useEffect(() => {
        const imageTimestamp = localStorage.getItem(imageKey + "Timestamp")
        if (imageTimestamp && (Date.now() - Number(imageTimestamp)) <= 10 * 60000) {
            const img = localStorage.getItem(imageKey)
            if (img) {
                setImage(img)
            }
        }
    }, [])

    const onContinue = () => {
        trackClickEvent("Continue")
        const canvas = document.getElementsByTagName("canvas")[0]
        canvas.toBlob(async (blob) => {
            if (!blob) {
                return;
            }
            // setBlob(blob)
            navigateNext()
        })
    }

    useEffect(() => {
        const constraints = {
            audio: false,
            video: {
                facingMode: "environment",
            },
        };

        let stream: null | MediaStream = null;

        const getMedia = async () => {
            try {
                stream = await navigator.mediaDevices.getUserMedia(constraints);
                setCameraReady(true);
            } catch (err) {
                setCameraReady(false);
            }
        };

        getMedia();

        return () => {
            if (stream) {
                stream.getTracks().forEach(track => track.stop());
                stream = null;
            }
        };
    }, []);

    return (
        <Wrapper style={{ backgroundColor: "#FCFCFC" }}>
            <CameraHeader>
                <>
                    <PageTitle white>
                        {title}
                    </PageTitle>
                    <Subtitle1 white center sx={{ px: 4 }}>
                        {instructions}
                    </Subtitle1>
                </>
            </CameraHeader>
            {cameraReady &&
                <Camera
                    ref={camera}
                    aspectRatio="cover"
                    facingMode="environment"
                    errorMessages={{
                        noCameraAccessible: 'No camera device accessible. Please connect your camera or try a different browser.',
                        permissionDenied: 'Permission denied. Please refresh and give camera permission.',
                        switchCamera:
                            'It is not possible to switch camera to different one because there is only one video device accessible.',
                        canvas: 'Canvas is not supported.',
                    }}
                    videoReadyCallback={() => {
                    }}
                />
            }
            {image ?
                (<>
                    <FullScreenImagePreview
                        image={image}
                        onClick={() => {
                            setShowImage(!showImage);
                        }}
                    />

                    <Control style={{ gridTemplateColumns: "100px 1fr 100px" }}>
                        <H6
                            center
                            onClick={() => {
                                localStorage.removeItem(imageKey)
                                setImage(null)
                            }}
                            white
                        >Back</H6>
                        <Box />
                        <H6
                            center
                            onClick={onContinue}
                            white
                        >Continue</H6>
                    </Control>
                </>)
                :
                (<Control>
                    <H6 onClick={() => navigateBack()} center white>Cancel</H6>
                    <Box
                        onMouseDown={() => { setPressed(true) }}
                        onMouseUp={() => {
                            setPressed(false);
                            if (camera.current) {
                                const photo = camera.current.takePhoto();
                                localStorage.setItem(imageKey + "Timestamp", Date.now().toString())
                                localStorage.setItem(imageKey, photo)
                                setImage(photo);
                            }
                        }}
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "80px",
                            height: "80px",
                            background: "#FCFCFC",
                            borderRadius: "50%",
                            margin: "auto",
                        }}>
                        <Box sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "90%",
                            height: "90%",
                            background: "black",
                            borderRadius: "50%",
                            margin: "auto",
                        }}>
                            <Box sx={{
                                width: pressed ? "75%" : "89%",
                                height: pressed ? "75%" : "89%",
                                border: pressed ? "solid 10% black" : "solid 4px black",
                                background: "#FCFCFC",
                                borderRadius: "50%",
                                margin: "5% auto",
                                transition: "0.5s ease"
                            }} />
                        </Box>
                    </Box>
                </Control>)
            }
        </Wrapper>
    );
}

const Wrapper = styled.div({
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    zIndex: 100,
    transform: "translate3d(0, 0, 10px)",
});

const CameraHeader = ({ children }: { children: JSX.Element }) => (
    <Box sx={{
        position: "fixed",
        width: "100vw",
        height: "140px",
        top: 0,
        left: 0,
        background: "rgba(0,0,0,0.9)",
        zIndex: 10,
        transform: "translate3d(0, 0, 10px)",
        paddingTop: "24px",
        boxSizing: "border-box"
    }}>
        {children}
    </Box>
)

const Control = styled.div`
  align-items: center;
  position: fixed;
  display: grid;
  grid-template-columns: 80px 1fr 80px;
  left: 0;
  bottom: 0;
  width: 20%;
  min-width: 130px;
  min-height: 130px;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  z-index: 10;
  align-items: center;
  padding: 0 24px;
  box-sizing: border-box;

  z-index: 1000;

  @media (max-aspect-ratio: 1/1) {
    flex-direction: row;
    bottom: 0;
    width: 100%;
    height: 20%;
  }

  @media (max-width: 400px) {
    padding: 10px;
  }
`;

const FullScreenImagePreview = styled.div<{ image: string | null }>`
  width: 100%;
  height: 100%;
  z-index: 100;
  position: absolute;
  background-color: black;
  ${({ image }) => (image ? `background-image:  url(${image});` : '')}
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`;
