import * as yup from "yup";
import { useFormImplementation } from "./useFormImplementation";

const phoneNumberValidator = yup.string()
    // must have 10 digits and allows for characters: -, (, ), and whitespace
    .length(10, "Must be 3 digit area code with 7 digit phone number.")
    .matches(/^[^a-zA-Z]+$/, "Invalid Phone number")
    .required("Phone Number is required.");

const schema = yup.object().shape({
    phoneNumber: phoneNumberValidator,
}).required();

export const usePhoneForm = () => {
    const { fieldProps, handleSubmit } = useFormImplementation(
        schema,
        Object.keys({ ...schema.fields })
    );

    return {
        phoneProps: fieldProps.phoneNumber,
        handleSubmit
    }
}
