import { FormControl, InputLabel, MenuItem, Select, Skeleton } from "@mui/material";
import { useEffect } from "react";

type SelectProps = {
    value: number | string,
    onChange: (val: number | string) => void,
    options: string[],
    label: string,
    disabled?: boolean,
    loading?: boolean,
}
export default function ({
    value, onChange, options, label, disabled, loading
}: SelectProps) {

    useEffect(() => {
        if (options.length === 1) {
            onChange(options[0])
        } else {
            onChange("")
        }
    }, [JSON.stringify(options)])

    if (loading) {
        return <Skeleton height={56} />
    }

    return (
        <FormControl fullWidth>
            <InputLabel>{label}</InputLabel>
            <Select
                disabled={disabled || options.length === 1}
                value={value}
                label={label}
                onChange={(e: any) => onChange(e.target.value)}
            >
                {options.map(o =>
                    <MenuItem value={o}>{o}</MenuItem>
                )}
            </Select>
        </FormControl>
    )
}
