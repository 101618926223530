import { SvgIconContainer } from "./SvgIconContainer";


export const AccountIcon = () => (
    <SvgIconContainer>
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_189_2640)">
                <path d="M11.9598 20.5099C9.96978 20.5099 7.96978 20.5099 5.97978 20.5099C5.07978 20.5099 4.52979 19.9599 4.52979 19.0599C4.52979 18.1799 4.52979 17.2999 4.52979 16.4299C4.52979 15.8799 4.77978 15.4699 5.26978 15.2199C6.71978 14.4599 8.23978 13.9499 9.85978 13.7199C12.9798 13.2699 15.9498 13.7799 18.7398 15.2399C19.2098 15.4799 19.4398 15.8799 19.4398 16.3999C19.4398 17.3099 19.4398 18.2299 19.4398 19.1399C19.4398 19.9399 18.8598 20.5199 18.0598 20.5199C16.0298 20.5199 13.9998 20.5199 11.9698 20.5199L11.9598 20.5099Z" fill="#2F2E2E" />
                <path d="M11.8698 12.29C9.57984 12.2 7.85984 10.33 7.96984 8.05995C8.07984 5.81995 9.99984 4.13995 12.2398 4.25995C14.3798 4.36995 16.0898 6.26995 15.9898 8.46995C15.8998 10.62 14.0098 12.37 11.8698 12.28V12.29Z" fill="#2F2E2E" />
            </g>
            <defs>
                <clipPath id="clip0_189_2640">
                    <rect width="24" height="24" fill="white" transform="translate(0 0.5)" />
                </clipPath>
            </defs>
        </svg>

    </SvgIconContainer>
);
