export function createFetchOnce<T>(cb: (...params: any[]) => Promise<T>, timeout: number = 2000) {
    const fetchingRef = { current: false }
    return function (...params: any[]) {
        if (!fetchingRef.current) {
            fetchingRef.current = true
            setTimeout(() => {
                fetchingRef.current = false
            }, timeout)
            return cb(...params)
        }
    }
}
