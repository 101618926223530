import { useState } from "react";
import * as yup from "yup";

import { PhoneInput } from "../../components/Input/Input";
import { Section } from "../../components/layout/Section";
import { StorSelfButton } from "../../components/buttons/StorSelfButton";
import { Subtitle1, PageTitle } from "../../brand/typographies";
import { useFormImplementation } from "../../hooks/useFormImplementation";
import { useSuccessError } from "../../hooks/useSuccessError";
import agents from "../../api/agents";
import { captureException } from "../../utils/errorHandling";

export const EnterPhoneNumber = ({ setVerifyingOtp }: { setVerifyingOtp: (arg0: boolean) => void; }) => {
    const [submitting, setSubmitting] = useState(false);

    const { fieldProps, handleSubmit } = useFormImplementation(
        schema,
        Object.keys({ ...schema.fields })
    );

    const { onError } = useSuccessError();

    const onSubmit = async (formValues: any, e: any) => {
        setSubmitting(true);
        try {
            await agents.OTP.sendOTP(formValues.phoneNumber);

            setSubmitting(false);

            // sets state to move to the next step in the otp verification
            setVerifyingOtp(true);
        } catch (error) {
            captureException(error)

            if (error instanceof Error) {
                onError(error.message || "Oops! Something went wrong.");
            } else {
                onError("An unexpected error occurred.");
            }
        }
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <PageTitle>Verify Number</PageTitle>

            <Subtitle1 center black>Enter Phone Number for SMS Verification:</Subtitle1>
            <Section sx={{ my: 4 }}>
                <PhoneInput
                    fullWidth
                    label="Phone Number"
                    {...fieldProps.phoneNumber} />
            </Section>

            <StorSelfButton
                pending={submitting}
                type='submit'
            >Submit</StorSelfButton>
        </form>
    );
};

const phoneNumberValidator = yup.string()
    // must have 10 digits and allows for characters: -, (, ), and whitespace
    .length(10, "Must be 3 digit area code with 7 digit phone number.")
    .matches(/^[^a-zA-Z]+$/, "Invalid Phone number")
    .required("Phone Number is required.");

const schema = yup.object().shape({
    phoneNumber: phoneNumberValidator,
}).required();
