import React, { LazyExoticComponent, useLayoutEffect, useRef, useState } from "react";
import { Box, styled } from "@mui/material";

import { Body2, H5 } from "../../../brand/typographies";
import {
    CARD_TYPE_AMERICAN_EXPRESS,
    CARD_TYPE_DINERS,
    CARD_TYPE_DISCOVER,
    CARD_TYPE_JCB,
    CARD_TYPE_MASTERCARD,
    CARD_TYPE_VISA,
    IPaymentMethod,
    CardType,
    CARD_TYPE_AMERICAN_EXPRESS2
} from "../../../types/PaymentMethod";

// Lazy load icon components
const VisaIcon = React.lazy(() => import("../[icons]/VisaIcon"));
const MasterCardIcon = React.lazy(() => import("../[icons]/MasterCardIcon"));
const AmexIcon = React.lazy(() => import("../[icons]/AmexIcon"));
const DinersIcon = React.lazy(() => import("../[icons]/DinersIcon"));
const DiscoverIcon = React.lazy(() => import("../[icons]/DiscoverIcon"));
const JcbIcon = React.lazy(() => import("../[icons]/JcbIcon"));

type IconMap = { [K in CardType]: LazyExoticComponent<() => JSX.Element> };

const creditIcon: IconMap = {
    [CARD_TYPE_VISA]: VisaIcon,
    [CARD_TYPE_MASTERCARD]: MasterCardIcon,
    [CARD_TYPE_AMERICAN_EXPRESS]: AmexIcon,
    [CARD_TYPE_AMERICAN_EXPRESS2]: AmexIcon,
    [CARD_TYPE_DISCOVER]: DiscoverIcon,
    [CARD_TYPE_DINERS]: DinersIcon,
    [CARD_TYPE_JCB]: JcbIcon
}

// TODO: style the gradient....
export const CreditCard = ({
    selected,
    paymentMethod,
    setHeight,
    height
}: {
    selected?: boolean;
    height: string;
    setHeight: (null | ((arg0: string) => void))
    paymentMethod: IPaymentMethod;
}) => {
    const IconComponent = creditIcon[paymentMethod.cardType];

    const ref = useRef<HTMLDivElement>(null)

    useLayoutEffect(() => {
        if (!setHeight) {
            return () => { }
        }

        if (ref.current?.clientWidth) {
            setHeight(ref.current?.clientWidth / 1.7 + "px")
        }
    }, [])

    return (
        <CardContainer ref={ref} selected={selected} sx={{ height }}>
            <Card>
                <Box>
                    <CardTextGray>Full Name</CardTextGray>
                    <H5 white>{paymentMethod.fullName.trim() || paymentMethod.nameOnCard}</H5>
                </Box>
                <Box sx={{ color: "white", display: "flex", justifyContent: "end" }}>
                    <React.Suspense fallback={null}>
                        {/** TODO: log when IconComponent is nullish */}
                        {IconComponent && <IconComponent />}
                    </React.Suspense>
                </Box>

                <CardTextGray sx={{ display: "flex", alignItems: "end" }}>
                    XXXX XXXX XXXX {paymentMethod.cardNumber.slice(-4)}
                </CardTextGray>

                <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "end", alignItems: "end" }}>
                    <CardTextGray>Valid thru</CardTextGray>
                    <CardTextGray>{paymentMethod.expirationDate}</CardTextGray>
                </Box>
            </Card>
        </CardContainer>
    );
};

const CardTextGray = ({ children, sx = {} }: any) =>
    <Body2 sx={{ color: "white", opacity: 0.6, ...sx }}>
        {children}
    </Body2>


const Card = styled("div")({
    width: "100%",
    height: "100%",
    background: "linear-gradient(106deg, #2F2E2E 6.78%, #C72127 101.9%)",
    borderRadius: "8px",
    boxSizing: 'border-box',
    padding: "30px 18px 18px 18px",
    display: "grid",
    gridTemplateColumns: "2fr 1fr",
    gridTemplateRows: "1fr 1fr",
})

const CardContainer = styled("div")(({ selected }: { selected?: boolean }) => ({
    borderRadius: "8px",
    border: selected ? "2px solid #54B239" : "",
    boxSizing: 'border-box',
    width: "100%",
    maxHeight: "160px",
    padding: "2px",
}))
