import { useForm, UseFormRegisterReturn } from "react-hook-form";
import { ObjectSchema } from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect } from "react";

export type FormObj = { [key: string]: string }

interface IFieldProps extends UseFormRegisterReturn {
    error: boolean,
    helperText?: string,
    setValue: (val: any) => void
}

export function useFormImplementation<T extends FormObj>(schema: ObjectSchema<FormObj>, fieldNames: string[], defaultValues?: T) {
    const { register, handleSubmit, formState: { errors }, setValue } = useForm({
        mode: "onBlur",
        resolver: yupResolver(schema),
    });

    useEffect(() => {
        if (defaultValues) {
            Object.keys(defaultValues).forEach((key) => {
                setValue(key, defaultValues[key])
            })
        }
    }, [])

    const fieldProps: { [key in keyof T]: IFieldProps } = fieldNames.reduce((acc, name: string) => {
        return {
            ...acc,
            [name]: {
                ...register(name),
                error: !!errors[name],
                helperText: errors[name]?.message || " ",
                setValue: (val: string) => setValue(name, val)
            }
        }
    }, {}) as { [key in keyof T]: IFieldProps };

    return {
        fieldProps,
        errors,
        handleSubmit
    }
}
