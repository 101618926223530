import { Subtitle1 } from "../../../brand/typographies";
import { Box } from "@mui/material";
import useAnalytics from "../../../hooks/useAnalytics";


export const LookupAnotherAccount = ({ navigateToLookup }: { navigateToLookup: () => void; }) => {
    const { trackClickEvent } = useAnalytics()
    return (
        <Box
            mt={3}
            display='flex'
            width='100%'
            justifyContent={'center'}
            alignItems={'center'}
            flexDirection={"column"}
        >
            <Subtitle1
                onClick={() => {
                    trackClickEvent("Lookup Different Account")
                    navigateToLookup()
                }}
                brandColor
                underlined
                center>Look up another account</Subtitle1>
        </Box>
    );
};
