import { useContext } from "react";
import { Box } from "@mui/material";
import Check from '@mui/icons-material/CheckRounded';
import Error from '@mui/icons-material/Close';

import { Colors } from "../brand/colors";
import { PopoverContext } from "../providers/PopoverContextProvider";
import { Subtitle1, PageTitle } from "../brand/typographies";
import useAnalytics from "./useAnalytics";
import { StorSelfButton } from "../components/buttons/StorSelfButton";

// const Check = React.lazy(() => import('@mui/icons-material/CheckRounded'));
// const Error = React.lazy(() => import('@mui/icons-material/Close'));

export const useSuccessError = (callback?: () => void) => {
    const { onShowPopover, onClosePopover } = useContext(PopoverContext);

    const handleContinue = () => {
        onClosePopover();
        callback && callback();
    };

    const onSuccess = (message?: string | any, header?: string) => {
        onShowPopover(<SuccessErrorModal
            handleContinue={handleContinue}
            options={{
                error: false,
                message,
                header
            }} />,
            {
                light: true
            });
    };

    const onError = (message?: string | any, header?: string) => {
        onShowPopover(<SuccessErrorModal
            handleContinue={handleContinue}
            options={{
                error: true,
                message: message || "Something went wrong",
                header
            }} />,
            {
                light: true
            });
    };

    return { onError, onSuccess };
};

function SuccessErrorModal({ options, handleContinue }: any) {
    const Icon = options?.error ? Error : Check;
    const { trackClickEvent } = useAnalytics("SuccessErrorModal")

    return (
        <Box
            sx={{
                marginTop: "20vh",
            }}>
            <Box sx={{
                backgroundColor: Colors.lightgrey(),
                borderRadius: "25px",
                padding: "20px",
                width: "180px",
                margin: "auto",
            }}>
                <Icon sx={{
                    fontSize: "180px",
                    color: Colors.primary(),
                    backgroundColor: Colors.white(),
                    borderRadius: "50%",
                }} />
            </Box>
            <PageTitle>
                {options?.header || (options?.error ? "Error" : "Congrats!")}
            </PageTitle>
            <Subtitle1 center sx={{ mb: 4, mt: 1 }}>
                {options?.message}
            </Subtitle1>

            <StorSelfButton onClick={() => {
                trackClickEvent("Continue")
                handleContinue()
            }}>Continue</StorSelfButton>
        </Box>
    );
}
