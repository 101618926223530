import { SvgIconContainer } from "./SvgIconContainer";


export const FacilityIcon = () => (
    <SvgIconContainer>
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_189_2645)">
                <path d="M9.85 3.47996C10.52 3.92996 11.19 4.38996 11.86 4.83996C13.32 5.83996 14.77 6.83996 16.23 7.83996C16.43 7.97996 16.52 8.12996 16.52 8.37996C16.51 8.93996 16.52 9.49996 16.52 10.05V10.24C15.54 10.2 14.61 10.35 13.7 10.72C13.7 10.51 13.7 10.32 13.7 10.14C13.7 9.83996 13.53 9.66996 13.23 9.66996C10.92 9.66996 8.6 9.66996 6.29 9.66996C5.97 9.66996 5.81 9.83996 5.81 10.17C5.81 12.37 5.81 14.57 5.81 16.77C5.81 16.84 5.81 16.91 5.81 16.99C5.75 16.99 5.7 16.99 5.66 16.99C5.1 16.99 4.53 16.99 3.97 16.99C3.43 16.99 3 16.56 3 16.01C3 13.43 3 10.86 3 8.27996C3 8.07996 3.1 7.95996 3.26 7.84996C5.1 6.58996 6.93 5.31996 8.77 4.05996C9.06 3.85996 9.36 3.65996 9.66 3.45996C9.73 3.45996 9.8 3.45996 9.87 3.45996L9.85 3.47996Z" fill="#2F2E2E" />
                <path d="M21.0201 17.0402C20.9701 17.3102 20.9301 17.6002 20.8601 17.8602C20.4101 19.4602 19.4101 20.5902 17.8601 21.1902C15.4101 22.1402 12.5901 20.9002 11.6201 18.4602C10.5901 15.8802 11.8501 13.0302 14.4701 12.0302C16.8901 11.1102 19.7101 12.3602 20.6701 14.8002C20.8301 15.2102 20.8901 15.6602 21.0001 16.0902C21.0101 16.1402 21.0201 16.1802 21.0301 16.2302V17.0402H21.0201ZM15.4001 12.9502V14.7402H16.8901C16.8901 14.1702 16.8901 13.6102 16.8901 13.0602C16.8901 13.0202 16.8401 12.9502 16.8001 12.9402C16.3301 12.8602 15.8701 12.8602 15.4001 12.9502ZM12.4601 15.8902C12.3601 16.3702 12.3701 16.8302 12.4401 17.2902C12.4601 17.4002 12.5301 17.3902 12.6101 17.3902C13.1201 17.3902 13.6301 17.3902 14.1401 17.3902C14.1801 17.3902 14.2201 17.3802 14.2501 17.3702V15.8902H12.4601ZM18.0301 17.3802H19.8201C19.9301 16.8802 19.9301 16.3802 19.8201 15.8902H18.0301V17.3802ZM16.8801 20.3102V18.5202H15.3901V20.3102C15.8901 20.4102 16.3801 20.4202 16.8801 20.3102ZM16.8801 17.3802V15.9002H15.4001V17.3802H16.8801ZM14.2501 13.3902C13.6701 13.7302 13.2301 14.1802 12.8901 14.7502H14.2501V13.3902ZM19.3901 14.7502C19.0401 14.1702 18.6101 13.7302 18.0301 13.4002V14.7502H19.3901ZM14.2501 19.8802V18.5302H12.9001C13.2501 19.1002 13.6801 19.5402 14.2501 19.8802ZM18.0301 18.5302V19.8802C18.6001 19.5402 19.0401 19.1002 19.3801 18.5302H18.0301Z" fill="#2F2E2E" />
                <path d="M6.66016 10.5298H12.8502C12.8502 10.7298 12.8502 10.9198 12.8502 11.1098C12.8502 11.1498 12.8002 11.1998 12.7602 11.2198C12.5802 11.3498 12.4102 11.4698 12.2202 11.5898C12.1702 11.6198 12.1002 11.6498 12.0502 11.6498C10.2802 11.6498 8.51016 11.6498 6.73016 11.6498C6.71016 11.6498 6.70016 11.6498 6.67016 11.6498V10.5298H6.66016Z" fill="#FCFCFC" />
                <path d="M6.6499 13.8998V12.5098H11.2499C11.1099 12.6898 10.9799 12.8598 10.8599 13.0398C10.6999 13.2898 10.5699 13.5498 10.4099 13.7898C10.3799 13.8398 10.2999 13.8998 10.2499 13.8998C9.0799 13.8998 7.8999 13.8998 6.7299 13.8998C6.7099 13.8998 6.6799 13.8998 6.6499 13.8998Z" fill="#FCFCFC" />
                <path d="M9.74016 16.1498H6.66016V14.7598H10.0002C9.91016 15.2198 9.83016 15.6798 9.74016 16.1498Z" fill="#FCFCFC" />
            </g>
            <defs>
                <clipPath id="clip0_189_2645">
                    <rect width="24" height="24" fill="white" transform="translate(0 0.5)" />
                </clipPath>
            </defs>
        </svg>
    </SvgIconContainer>
);
