import { useCallback, useContext, useEffect } from 'react'
// import { AnalyticsContext } from '../contexts/AnalyticsProvider';
import { analyticsService } from "../services/analytics-service"
import { createThrottle } from '../createThrottle'
import { AnalyticsContext } from '../providers/AnalyticsProvider'
import { AuthContext } from '../providers/auth-provider/AuthProvider'
// import { createThrottle } from '../utils/createThrottle';
// import { useTryUseLoggedInUser } from './useLoggedInUser';

interface IAnalyticsHook {
    trackViewEvent: (ctx?: string) => void,
    trackClickEvent: (arg0: string, properties?: { [key: string]: any }) => void,
    trackEvent: (arg0: string, properties?: { [key: string]: any }) => void,
}

const throttledTrackViewEvent = createThrottle<void>(async (componentContext, pageName, loggedInUser) =>
    analyticsService.trackViewEvent(componentContext, pageName, loggedInUser),
    500
)

const useAnalytics = (componentContext?: string, shouldTrackViewEvent = false): IAnalyticsHook => {
    const authUser = useContext(AuthContext)
    const { pageName } = useContext(AnalyticsContext)

    const trackViewEvent = useCallback(
        (ctx?: string) => throttledTrackViewEvent(ctx || componentContext, pageName, authUser),
        [authUser]
    )

    const trackClickEvent = useCallback(
        createThrottle<any>(
            (clickContext: string, properties: { [key: string]: any } = {}): Promise<any> =>
                analyticsService.trackClickEvent(
                    componentContext || "",
                    clickContext,
                    authUser,
                    { pageName, ...properties }
                ),
            500
        ),
        [authUser]
    )

    const trackEvent = useCallback(
        (event: string, properties: { [key: string]: any } = {}): Promise<any> =>
            analyticsService.trackEvent(
                pageName + " - " + event,
                authUser,
                { pageName, ...properties }
            ),
        [authUser]
    )

    useEffect(() => {
        if (shouldTrackViewEvent) {
            throttledTrackViewEvent(componentContext, pageName, authUser)
        }
    }, []);

    //TODO: have each event is added to a queue and awaited before next event instead of awaiting in click methods.
    return {
        trackViewEvent,
        trackClickEvent,
        trackEvent
    };
}

export default useAnalytics;
