import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { StringParam, useQueryParam } from "use-query-params";

import { AccountContext } from "../../../../providers/account-provider/AccountProvider";
import { AuthContext } from "../../../../providers/auth-provider/AuthProvider";
import { PageTitle, Subtitle1 } from "../../../../brand/typographies";
import { ColumnedRow } from "../../../../components/ColumnedRow";
import { environment } from "../../../../globalContext";
import { payDelinquent } from "../../../../api/demo/payDelinquent";
import { PaymentsContext } from "../../../../providers/payments-provider/PaymentsProvider";
import { useListenToFacilityChange } from "../../../../hooks/useListenToFacilityChange";
import { useStoreSelfNavigation } from "../../../../hooks/useStorSelfNavigation";
import { useSuccessError } from "../../../../hooks/useSuccessError";
import useAnalytics from "../../../../hooks/useAnalytics";
import { handlePaymentResponse } from "../../[utils]/handlePaymentResponse";
import { Skeleton } from "@mui/material";
import agents from "../../../../api/agents";
import { ErrorLogType } from "../../../../types/ErrorLogType";

const targetPaymentRoute = _context.companyConfig.subdomain === "demo" ? "/demo" : ""

export const useIframeListeners = (onMessage: (action: string, value: any) => void) => {
    useEffect(() => {
        const handleMessage = (event: { data: { action: string; value: any; }; }) => {
            const { action, value } = event.data;

            onMessage(action, value)
        };

        window.addEventListener('message', handleMessage);

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener('message', handleMessage);
        };
    }, [onMessage]);
};

export const NewPaymentMethodPage = () => {
    const { state: { ledger, paymentAmount, loading, ...state }, flowTitle } = useContext(PaymentsContext);
    const authUser = useContext(AuthContext)
    const [isConfirming] = useState(false);
    const { refetchLedgers } = useContext(AccountContext)
    const [callbackRoute] = useQueryParam("callback", StringParam)

    const navigate = useStoreSelfNavigation()

    useListenToFacilityChange(PaymentsContext);

    const { onSuccess, onError } = useSuccessError(() => {
        // callbackRoute is the page that referred the user to the payment flow.
        if (callbackRoute) {
            navigate(callbackRoute, undefined, { replace: true })
        } else {
            navigate("/account", undefined, { replace: true })
        }
    })

    const { trackEvent } = useAnalytics()

    const iframeRef = useRef<HTMLIFrameElement | null>(null);

    const getIframeUrl = () => {
        return environment.paymentServerBaseUrl
            + targetPaymentRoute
            + "?phoneNumber=" + state.phoneNumber
            + "&ledgerId=" + state.ledgerId
            + "&months=" + state.prePayMonths
            + "&facilityId=" + state.facilityId
            + "&unitName=" + (ledger?.facilityUnit?.unitName ? encodeURIComponent(ledger!.facilityUnit.unitName) : "x")
            + "&amount=" + paymentAmount
            + "&color=" + _context.companyConfig.primaryColor.replace("#", "")
    }

    const iframeUrl = ledger ? getIframeUrl() : null;

    // since the payment happens within an iframe,
    // we need to listen for the result to be posted from the iframe.
    useIframeListeners(
        useCallback(async (action, result) => {
            if (action === "error") {
                await agents.Logger.logError({
                    message: JSON.stringify({
                        error: result,
                        iframeUrl
                    }),
                    type: ErrorLogType.Payment,
                })
            }
            if (action === "paymentResult") {
                if (_context.companyConfig.subdomain === "demo") {
                    // update ledger for demo
                    payDelinquent()
                }

                console.log({ action, result })
                handlePaymentResponse(
                    result,
                    onError,
                    onSuccess,
                    refetchLedgers,
                    authUser.phoneNumber,
                    state.phoneNumber,
                    trackEvent,
                    false
                )
            }
        }, [])
    )

    return (
        <>
            <PageTitle>{isConfirming ? "Confirm & Submit" : flowTitle}</PageTitle>

            <Subtitle1 center>
                {isConfirming
                    ? "Confirm details and Submit Payment."
                    : "Enter credit card information."}
            </Subtitle1>

            <ColumnedRow
                loading={loading}
                data={[
                    { label: "Unit", value: ledger?.facilityUnit.unitName || "" },
                    { label: "Total Due", value: "$" + paymentAmount }
                ]}
            />

            {iframeUrl ?
                <iframe
                    sandbox="allow-same-origin allow-scripts allow-forms"
                    ref={iframeRef}
                    src={iframeUrl}

                    style={{ border: 'none', width: '100%', height: '460px', minHeight: "136px" }}
                />
                : <Skeleton height="460px" width="100%" />
            }
        </>
    )
}
