import mixpanel from 'mixpanel-browser';
import { createThrottle } from '../createThrottle';
import { kioskFacilityId } from '../globalContext';

type AuthUser = { phoneNumber: string }

const mixPanelToken = _context.environment?.mixPanelToken

const initAnalytics = () => {
    mixpanel.init(mixPanelToken, { debug: window.location.host.includes("localhost") })
}

initAnalytics()

const trackViewEvent = (analyticsContext: string, pageName: string, user: AuthUser | null) => {
    const viewEvent = `View - ${analyticsContext}`

    // don't log a view event for refreshes basically...
    const storedData = localStorage.getItem("lastEventViewed");
    const data = storedData ? JSON.parse(storedData) : null;
    // Check if the last event is the same and if it's within the 5-minute window
    if (data && data.event === viewEvent && (new Date().getTime() - data.timestamp) < 5 * 60000) {
        return;
    }

    // Store the new event and the current timestamp
    localStorage.setItem("lastEventViewed", JSON.stringify({ event: viewEvent, timestamp: new Date().getTime() }));


    trackEvent(
        viewEvent,
        user,
        { pageName: pageName }
    )
}

const trackClickEvent = async (analyticsContext: string, clickContext: string, user: AuthUser | null, properties: { [key: string]: any } = {}): Promise<any> => {
    return new Promise((resolve) => {
        trackEvent(
            analyticsContext
                ? `Click - ${analyticsContext} - ${clickContext}`
                : `Click - ${clickContext}`,
            user,
            {
                buttonClicked: clickContext,
                ...properties
            },
            (res: any) => {
                resolve(res)
            }
        )
    })
}

const trackAnalyticsEvent = async (event: string, user: AuthUser | null, properties: { [key: string]: any } = {}): Promise<any> => {
    return new Promise((resolve) => {
        trackEvent(
            event,
            user,
            {
                ...properties
            },
            (res: any) => {
                resolve(res)
            }
        )
    })
}

const isDNTEnabled = () => {
    // @ts-ignore
    const dnt = navigator.doNotTrack || window.doNotTrack;
    return (dnt === 'yes' || dnt === '1');
};

export const identify = (phoneNumber: string) => {
    if (isDNTEnabled()) return;

    if (phoneNumber) {
        mixpanel.identify(phoneNumber); // This sets the distinct_id for the user
        mixpanel.people.set({ // This sets properties on the user profile
            "$phone": phoneNumber, // Reserved property for phone number
            "$name": phoneNumber // Reserved property for user's name
        });
    }
}

export const trackEvent = (eventName: string, user: AuthUser | null, properties: { [key: string]: any } = {}, callback?: any) => {
    if (isDNTEnabled()) return;

    if (user?.phoneNumber) {
        mixpanel.identify(user.phoneNumber); // This sets the distinct_id for the user
        mixpanel.people.set({ // This sets properties on the user profile
            "$phone": user.phoneNumber, // Reserved property for phone number
            "$name": user.phoneNumber // Reserved property for user's name
        });
    }

    if (kioskFacilityId) {
        eventName = `Kiosk - ${eventName}`
    }

    mixpanel.track(eventName, {
        ...user,
        ...properties,
    }, callback)
}

const trackAppLoad = createThrottle((user: AuthUser | null) => {
    return new Promise((resolve) => {
        trackEvent("App Loaded", user, {}, (res: any) => {
            resolve(res)
        })
    })
})

export const analyticsService = { initAnalytics, trackViewEvent, trackClickEvent, trackAppLoad, trackEvent: trackAnalyticsEvent };
