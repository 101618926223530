import React, { useContext, useEffect, useRef } from "react";
import { IAccountFlowContext } from "../types/AccountFlowContext";

export const useListenToFacilityChange = <T extends IAccountFlowContext>(Context: React.Context<T>) => {
    const { lookupAccount, state: { phoneNumber, facilityId } } = useContext(Context);
    const previousFacilityId = useRef("");

    useEffect(() => {
        if (!previousFacilityId.current) {
            previousFacilityId.current = facilityId;
        } else if (facilityId !== previousFacilityId.current) {
            previousFacilityId.current = facilityId;
            lookupAccount(phoneNumber, true);
        }
    }, [facilityId]);
};
