
import { FacilityAddress } from "../../components/FacilityAddress";
import { Section } from "../../components/layout/Section";
import { PageTitle } from "../../brand/typographies";
import { RentSpaceButton } from "../../components/buttons/NavigationButtons/RentSpaceButton";
import { ContactUsButton } from "../../components/buttons/NavigationButtons/ContactUsButton";

export const NewCustomerPage = () => {
    return (
        <>
            <PageTitle>New Customers</PageTitle>

            <FacilityAddress />

            <Section sx={{ my: 4, px: 4, boxSizing: "border-box", }}>
                <RentSpaceButton />

                <ContactUsButton />
            </Section>
        </>
    );
};
