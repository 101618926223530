import { useEffect, useState } from "react";
import agents from "../api/agents";

export const usePrePayMonthsAllowed = () => {
    const [allowedPrePayMonths, setAllowedPrePayMonths] = useState(6);
    const [pendingPrePayMonths, setPendingPrePayMonths] = useState(true);

    useEffect(() => {
        const fetchAllowedPrePayMonths = async () => {
            setPendingPrePayMonths(true)
            // const { data } = await agents.Payments.getAllowedPrePayMonths()
            // setAllowedPrePayMonths(data)
            setPendingPrePayMonths(false)
        }

        fetchAllowedPrePayMonths()
    }, [])

    return { allowedPrePayMonths, pendingPrePayMonths }
}