import { SvgIconContainer } from "./SvgIconContainer";


export const WifiIcon = () => (
    <SvgIconContainer>
        <svg width="126" height="108" viewBox="0 0 126 108" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M62.9837 0C75.6713 0.0780853 87.7852 2.6972 99.2701 8.10297C107.782 12.1081 115.392 17.4195 122.149 23.9575C123.236 25.0084 124.225 26.0935 124.725 27.5641C126.102 31.6115 123.358 36.0526 119.116 36.6171C116.793 36.9262 114.797 36.2511 113.144 34.6194C103.322 24.9255 91.6787 18.555 78.1909 15.5813C71.0835 14.0147 63.8931 13.5885 56.6407 14.2408C46.784 15.1274 37.5123 17.9303 28.8306 22.6708C22.9358 25.8885 17.6326 29.8985 12.8672 34.6227C9.14158 38.3155 2.92571 36.8839 1.3041 31.9125C0.427298 29.225 1.03846 26.7735 3.03979 24.7774C9.74296 18.0963 17.3441 12.6596 25.8693 8.51943C32.4438 5.32769 39.3197 3.03882 46.4906 1.63654C50.2635 0.897981 54.0689 0.39856 57.9103 0.214735C59.6003 0.133396 61.292 0.0732049 62.9837 0.00325355V0Z" fill="white" />
            <path d="M64.7 27.8146C77.2833 27.9724 90.0442 32.3728 101.175 41.325C102.795 42.6281 104.344 44.0352 105.804 45.5123C107.914 47.6467 108.447 50.2381 107.329 53.0134C106.216 55.7757 104.019 57.2202 101.05 57.352C99.091 57.4382 97.3749 56.7159 95.9782 55.3348C89.4086 48.8423 81.6135 44.6143 72.5668 42.7094C67.3434 41.6097 62.081 41.4324 56.7892 42.1416C48.9403 43.1958 41.7971 46.028 35.4394 50.7375C33.5636 52.1268 31.8279 53.7162 30.0987 55.2909C28.0811 57.1291 25.788 57.8303 23.1674 57.0641C20.5679 56.3044 18.9186 54.5247 18.3319 51.8779C17.7811 49.3889 18.4737 47.1912 20.2795 45.4082C28.1104 37.6696 37.3625 32.4103 48.0439 29.6822C52.9544 28.4279 57.9512 27.826 64.7 27.8146Z" fill="white" />
            <path d="M62.963 55.5853C72.8246 55.7171 81.3531 59.1154 88.4409 66.0032C90.4553 67.9602 91.1365 70.3662 90.333 73.0553C89.541 75.7004 87.6961 77.35 84.9712 77.8868C82.6048 78.3537 80.5154 77.6802 78.7846 76.0095C76.956 74.2445 74.9368 72.7576 72.6339 71.6693C69.1381 70.0165 65.4614 69.2828 61.5956 69.5447C56.0252 69.9205 51.2744 72.1004 47.2701 75.9721C45.2965 77.8803 42.9236 78.5001 40.2915 77.6948C37.6986 76.901 36.0786 75.092 35.5278 72.4436C35.0079 69.9498 35.7527 67.7748 37.565 65.9999C42.7573 60.9146 48.9276 57.6595 56.0675 56.2539C58.3394 55.8066 60.6373 55.5723 62.963 55.587V55.5853Z" fill="white" />
            <path d="M51.1758 95.1697C51.179 88.5812 56.3926 83.4325 63.0371 83.4553C69.6718 83.478 74.8414 88.6121 74.843 95.1811C74.8463 101.797 69.5496 107.008 62.8301 107.001C56.4219 106.993 51.1725 101.664 51.1758 95.1697Z" fill="white" />
        </svg>
    </SvgIconContainer>
);
