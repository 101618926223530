function MenuIcon() {
    return (
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="24" height="24" transform="translate(24 0.80249) rotate(90)" />
            <path d="M20.9998 13.0625C20.9198 13.3025 20.8698 13.5625 20.7598 13.7825C20.2898 14.7325 19.2298 15.2125 18.1998 14.9525C17.1998 14.7025 16.4998 13.7725 16.5198 12.7225C16.5398 11.6925 17.2898 10.7925 18.3098 10.5725C19.5398 10.3125 20.7598 11.1425 20.9798 12.3825C20.9798 12.4225 20.9998 12.4625 21.0098 12.4925C21.0098 12.6825 21.0098 12.8625 21.0098 13.0525L20.9998 13.0625Z" fill="#FCFCFC" />
            <path d="M12.0295 15.0325C10.7895 15.0325 9.77953 14.0325 9.77953 12.8025C9.77953 11.5625 10.7795 10.5425 12.0295 10.5425C13.2695 10.5425 14.2695 11.5525 14.2695 12.7825C14.2695 14.0225 13.2695 15.0325 12.0295 15.0325Z" fill="#FCFCFC" />
            <path d="M7.5293 12.7825C7.5293 14.0225 6.5293 15.0325 5.2893 15.0325C4.0493 15.0325 3.0393 14.0225 3.0393 12.7825C3.0393 11.5525 4.0493 10.5425 5.2893 10.5425C6.5293 10.5425 7.5293 11.5525 7.5293 12.7825Z" fill="#FCFCFC" />
        </svg>
    );
}

export default MenuIcon
