import { CameraView } from '../../../../components/Camera/CameraView';
import { useStoreSelfNavigation } from '../../../../hooks/useStorSelfNavigation';
import { useSearchParams } from 'react-router-dom';
import { auctionBuyerRoutes, mainRoutes } from '../../../../router/routes';

export function AuctionBuyerTakePhotoPage() {
    const navigate = useStoreSelfNavigation();
    const [searchParams] = useSearchParams();

    return (
        <CameraView
            title="Finalize Auction"
            navigateNext={() => { navigate(mainRoutes.AuctionBuyer + auctionBuyerRoutes.MoveoutConfirm + "?" + searchParams.toString()) }}
            navigateBack={() => { navigate(mainRoutes.AuctionBuyer + auctionBuyerRoutes.MoveoutOverview + "?" + searchParams.toString()) }}
        />
    );
}
