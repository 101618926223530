// todo: move this to a separate file for all swizl related functions

export const startChat = () => {
    const swizl = document.getElementsByClassName("sce-Dot")[0];

    if (swizl) {
        //@ts-ignore
        swizl.click();
    }
};
