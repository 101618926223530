import { styled } from "@mui/material";

export const Section = styled("div")({
    display: "grid",
    gridTemplateColumns: "1fr",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    gap: "24px"
});
