import { useEffect, useState } from "react";
import { NavigateOptions, useLocation } from "react-router-dom";
import { useAppContext } from "../../../hooks/useAppContext";
import { IFacility } from "../../../types/IFacility";
import { ILedger } from "../../../types/ILedger";
import { useStoreSelfNavigation } from "../../../hooks/useStorSelfNavigation";

export const useMoveoutEntryNavigation = () => {
    const navigate = useStoreSelfNavigation();
    const { pathname, search } = useLocation()
    const handleNavigate = (path: string, options: NavigateOptions = {}) => {
        if (path !== pathname + search) {
            navigate(path, undefined, options)
        }
    }

    return {
        lookup: (options: NavigateOptions = {}) =>
            handleNavigate("/moveout/lookup", options),
        selectUnit: (phoneNumber: string, facility: IFacility, options: NavigateOptions = {}) =>
            handleNavigate(`/moveout/select-unit?phoneNumber=${phoneNumber}&facilityId=${facility.id}`, options),
        overview: (phoneNumber: string, ledgerId: string, facility: IFacility, options: NavigateOptions = {}) =>
            handleNavigate(`/moveout/overview?phoneNumber=${phoneNumber}&ledgerId=${ledgerId}&facilityId=${facility.id}`, options),
        noUnitsFound: (phoneNumber: string, options: NavigateOptions = {}) =>
            handleNavigate(`/moveout/no-units-found?phoneNumber=${phoneNumber}`, options),
        takePhoto: (phoneNumber: string, ledger: ILedger, options: NavigateOptions = {}) =>
            handleNavigate(`/moveout/take-photo?phoneNumber=${phoneNumber}&ledgerId=${ledger.id}`, options),
        confirmMoveout: (phoneNumber: string, ledgerId: string, options: NavigateOptions = {}) =>
            handleNavigate(`/moveout/confirm-moveout?phoneNumber=${phoneNumber}&ledgerId=${ledgerId}`, options)
    }
}

export const useMoveoutEntryNavigate = () => {
    const { loadingAppContext, phoneNumber, facilityLedgers, selectedFacility } = useAppContext();
    const [pending, setPending] = useState(false);
    const navigateTo = useMoveoutEntryNavigation();

    const navigateToMoveout = () => {
        if (!phoneNumber) {
            // go to page to look up ledgers by phone number
            navigateTo.lookup()
        } else if (loadingAppContext) {
            // wait until app context is loaded before navigating...
            setPending(true)
        } else if (facilityLedgers.length > 1) {
            navigateTo.selectUnit(phoneNumber, selectedFacility!);
            /** ACTIONS on following page **
             * SELECT UNIT and [Continue] or
             * [Change Facility] or
             * [Look up a different account] */
        } else if (facilityLedgers.length === 1) {
            navigateTo.overview(phoneNumber, facilityLedgers[0].id, selectedFacility!)
            /** ACTIONS on following page **
             * [Continue and take photo] or
             * [Change Facility] or
             * [Look up a different account] */
        } else {
            navigateTo.noUnitsFound(phoneNumber)
            /** ACTIONS on following page **
             * [Lookup up by phone number] or
             * [Change Facility] */
        }
    }

    useEffect(() => {
        if (pending && !loadingAppContext) {
            navigateToMoveout()
        }
    }, [loadingAppContext, pending])

    return {
        pending,
        navigateToMoveout
    };
};
