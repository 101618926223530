import React from 'react';
import {
    Card,
    CardContent, Grid
} from '@mui/material';

export const FacilityAccountCard = ({ children }: { children: React.ReactNode; }) => (
    <Grid item xs={12}>
        <Card variant="outlined" sx={{ borderColor: "#2F2E2E", borderRadius: "12px" }}>
            <CardContent>
                {children}
            </CardContent>
        </Card>
    </Grid>
);
