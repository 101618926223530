import React from 'react';
import { createRoutesFromChildren, matchRoutes, useNavigationType, useLocation } from "react-router";
import * as Sentry from '@sentry/react';

import { environment } from '../globalContext';
import { debugLog } from './debugLog';
import { H1, H2, PageTitle } from '../brand/typographies';

let initialized = false;
export const initializeErrorHandling = () => {
    Sentry.init({
        dsn: environment.sentryDsn,
        integrations: [new Sentry.BrowserTracing({
            routingInstrumentation: Sentry.reactRouterV6Instrumentation(
                React.useEffect,
                useLocation,
                useNavigationType,
                createRoutesFromChildren,
                matchRoutes,
            ),
        })],
        // We recommend adjusting this value in production, or using tracesSampler
        // for finer control
        tracesSampleRate: 1.0,
    });

    initialized = true;
}

export const isInitialized = () => initialized

export const captureException = Sentry.captureException;

export const ErrorBoundary = ({ children, ...props }: { children: any, user?: { phoneNumber: string }, [key: string]: any }) => {
    console.log("ErrorBoundary")
    return <Sentry.ErrorBoundary
        onError={(err: any) => debugLog(err)}
        showDialog
        {...props}
    > {children}</Sentry.ErrorBoundary >
}

export const setUser = Sentry.setUser;

export const ExceptionPage = () => {
    return (
        <div style={{ width: "100%", padding: "24px", boxSizing: "border-box" }}>
            <PageTitle>
                Sorry!
            </PageTitle>
            <H1>
                Something went wrong on our end
            </H1>
            <H2>
                We've been notified and are working hard to address this.
            </H2>
        </div>
    )
}
