import { ButtonProps, getContrastRatio } from "@mui/material";
import { ChangeEvent, useCallback } from "react";
import { useStoreSelfNavigation } from "../../hooks/useStorSelfNavigation";
import LoadingButton from "@mui/lab/LoadingButton";
import useAnalytics from "../../hooks/useAnalytics";
import { Colors } from "../../brand/colors";

export type PrimaryButtonProps = ButtonProps & {
    text?: string;
    to?: string;
    pending?: boolean;
    analyticsTag?: string;
}

export const StorSelfButton = ({ to, text, children, analyticsTag, variant = "contained", ...props }: PrimaryButtonProps) => {
    const navigate = useStoreSelfNavigation();

    const { trackClickEvent } = useAnalytics("StorSelfButton")

    const onClick = useCallback(() => {
        if (to) {
            trackClickEvent("Navigate - " + to)
            return navigate(to);
        } else {
            analyticsTag && trackClickEvent(analyticsTag)
        }
    }, [navigate]);

    return <LoadingButton
        loading={!!props.pending}
        onClick={onClick}
        variant={variant}
        {...props}
        sx={{
            width: "100%",
            // if variant is contained, then we want to set the text color to white if the contrast ratio is high enough
            // black just looks bad...
            ...(variant === "outlined" && {
                color: Colors.primary(),
                "&:focus": {
                    color: Colors.primary(),
                },
                "&:hover": {
                    color: Colors.primary(),
                }
            }),
            ...(variant === "contained" && {
                color: getContrastRatio(Colors.primary(), "#FFFFFF") > 2.7 ? "#FFFFFF" : "#000000",
                borderColor: Colors.primary(),
                ...(props.sx || {})
            })
        }}
    >
        {text || children}
    </LoadingButton>
};
