import { isMobile } from "react-device-detect";
import { EmailIcon } from "../../icons";
import { StorSelfIconButton } from "../StorSelfIconButton";
import { companyConfig } from "../../../globalContext";
import { useContext } from "react";
import { FacilitiesContext } from "../../../providers/facility-provider/FacilitiesContext";

export const EmailUsButton = () => {
    const { selectedFacility } = useContext(FacilitiesContext);

    return (
        <StorSelfIconButton
            fullWidth={!isMobile}
            Icon={EmailIcon}
            text="Email us"
            href={`mailto: ${selectedFacility?.email || companyConfig.contactUsEmail}`} />
    );
}
