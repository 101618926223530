import { SvgIconContainer } from "./SvgIconContainer";


export const PaymentIcon = () => (
    <SvgIconContainer>
        <svg width="107" height="102" viewBox="0 0 107 102" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_18_5591)">
                <path d="M71.9176 67.5922C71.0111 66.613 70.1902 65.6754 69.3108 64.7942C68.4524 63.9337 67.5375 63.1295 66.6101 62.267C72.6152 54.7271 75.2199 46.333 74.0982 36.8993C73.0476 28.0739 68.9996 20.7653 62.1402 15.19C49.5346 4.9417 32.0038 5.08546 19.6552 15.4213C7.02662 25.9905 4.26739 43.2454 11.8871 57.0272C19.4797 70.7548 37.0251 78.7864 54.7982 71.2361C56.4525 73.1882 58.1256 75.1633 59.8112 77.153C42.5185 86.2867 19.3544 81.8615 7.07675 63.6712C-5.00453 45.7705 -1.17378 22.5008 15.1957 9.25438C32.7537 -4.95245 56.3752 -1.93358 70.3051 12.3878C86.5116 29.0531 84.6422 53.4499 71.9176 67.5901V67.5922Z" fill="white" />
                <path d="M73.7949 88.514C69.5715 83.3597 65.3961 78.274 61.2312 73.178C59.1717 70.6571 57.0599 68.1715 55.1237 65.5589C54.4135 64.6026 53.9874 63.3609 53.6992 62.1838C53.108 59.767 55.3534 57.7482 57.7764 58.4316C59.3596 58.8795 60.5941 59.8774 61.7095 61.0442C65.3335 64.8318 68.9574 68.6195 72.5835 72.405C72.8446 72.678 73.1307 72.9301 73.6049 73.3801C74.6263 72.2967 75.5432 71.1592 76.621 70.205C80.0382 67.1861 83.6872 64.5318 88.2678 63.5193C92.3555 62.6151 95.9732 63.5672 98.745 66.6923C102.166 70.5466 104.804 74.9176 106.225 79.9199C107.311 83.7451 107.405 87.5452 105.565 91.2183C104.337 93.6684 102.423 95.4852 100.226 97.0665C95.3905 100.546 89.9785 102.21 84.0528 101.979C78.6534 101.769 73.5339 100.246 68.8572 97.5915C65.5904 95.7372 62.5554 93.4705 59.4516 91.3371C58.8959 90.9558 58.4364 90.3808 58.0563 89.812C57.0307 88.2682 57.4004 86.8661 59.0735 86.0327C61.1476 85.0014 63.134 85.391 65.1225 86.4327C66.8269 87.3265 68.5835 88.1494 70.3819 88.8265C71.4451 89.2266 72.6629 89.5432 73.7929 88.5182L73.7949 88.514Z" fill="white" />
                <path d="M38.4996 20.3113C38.4996 19.3341 38.4808 18.4674 38.5038 17.6007C38.5518 15.7652 39.8239 14.5277 41.6035 14.561C43.3121 14.5944 44.5277 15.8632 44.5486 17.6445C44.557 18.4403 44.5486 19.2362 44.5486 19.9467C46.5225 20.5258 48.4441 21.0363 50.3261 21.6655C51.2013 21.9572 52.0347 22.4218 52.8242 22.9093C54.1652 23.7385 54.5056 25.2156 53.7579 26.6073C52.9704 28.0762 51.4728 28.6116 49.9835 28.0282C48.417 27.4157 46.8253 26.8615 45.24 26.2969C45.0583 26.2323 44.841 26.2615 44.5486 26.2407C44.5486 29.1991 44.5444 32.1097 44.5507 35.0202C44.5507 35.8869 44.2938 36.9349 44.6885 37.562C45.0436 38.1224 46.1381 38.2162 46.9047 38.5183C49.1438 39.3995 51.2869 40.4475 52.9579 42.233C55.1448 44.5664 55.9364 47.3603 55.6983 50.5208C55.5458 52.5417 54.9297 54.3481 53.6994 55.9606C51.6817 58.6107 48.8619 59.8754 45.7204 60.5942C45.3528 60.6775 44.9872 60.7692 44.5444 60.8733C44.5444 61.9859 44.557 63.0943 44.5423 64.2006C44.5173 65.9777 43.2828 67.2278 41.5554 67.2507C39.8448 67.2736 38.5581 66.036 38.5017 64.2631C38.4662 63.1588 38.4954 62.0546 38.4954 60.8963C34.7963 60.3546 31.4919 59.0274 28.6073 56.7773C27.5734 55.969 26.3557 55.2293 26.341 53.6189C26.3264 52.2188 27.047 51.3375 28.2084 50.7687C29.3634 50.2021 30.4496 50.475 31.3645 51.3229C33.3488 53.1584 35.6944 54.2376 38.4328 54.8231C38.4557 54.4293 38.4933 54.1043 38.4933 53.7814C38.4975 50.2062 38.4704 46.6311 38.5163 43.0559C38.5268 42.2913 38.2636 42.0288 37.5618 41.7809C34.861 40.8267 32.3483 39.5516 30.4559 37.2932C28.67 35.1619 28.0956 32.7097 28.1833 29.9825C28.3567 24.5656 33.0167 21.5176 37.1357 20.6134C37.568 20.5196 37.9983 20.4238 38.4975 20.3154L38.4996 20.3113ZM44.5925 54.6627C48.0514 53.9876 49.8081 52.2584 49.7746 49.552C49.7433 46.9623 48.1705 45.1769 44.5925 44.1872V54.6627ZM38.439 35.5535V26.4865C35.5837 27.222 34.2072 28.4553 34.107 30.3242C33.9775 32.7722 35.3811 34.4827 38.439 35.5515V35.5535Z" fill="white" />
            </g>
            <defs>
                <clipPath id="clip0_18_5591">
                    <rect width="107" height="102" fill="white" />
                </clipPath>
            </defs>
        </svg>
    </SvgIconContainer>
);
