import { Checkbox, FormControlLabel } from "@mui/material";
import { Colors } from "../../../brand/colors";

export const LabeledCheckBox = ({
    checked, handleCheck, setChecked, label
}: any) => <FormControlLabel
        componentsProps={{
            typography: { ...(checked ? { color: "primary" } : {}) }
        }}
        color={Colors.primary()}
        sx={{ m: 0, color: Colors.primary() }}
        onClick={() => {
            if (!checked) {
                handleCheck();
            } else {
                setChecked(false);
            }
        }}
        // sx={{ bgcolor: "lime" }}
        label={label}
        control={<Checkbox
            {...(checked ? { color: "primary" } : {})}
            checked={checked} />} />;
