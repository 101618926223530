import { SvgIconContainer } from "./SvgIconContainer";


export const LockIcon = () => (
    <SvgIconContainer>
        <svg width="64" height="93" viewBox="0 0 64 93" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_18_5586)">
                <path d="M58.1853 37.7625H40.6951C40.6951 36.6832 40.6951 35.6731 40.6951 34.6592C40.6912 31.7056 40.7259 28.7521 40.672 25.7986C40.5912 21.2473 36.6198 17.4334 32.025 17.4257C27.4225 17.418 23.3934 21.2012 23.3434 25.7525C23.2703 32.4546 23.3165 39.1567 23.3165 45.8588C23.3165 46.0393 23.3472 46.2198 23.3742 46.4772H64V93.0041H0V46.577H5.78005C5.78005 46.024 5.78005 45.5861 5.78005 45.1521C5.78775 38.4884 5.70308 31.8209 5.83008 25.161C6.06097 13.0434 15.4122 2.36228 27.3763 0.38045C41.9535 -2.03538 55.3107 7.32453 57.889 21.7773C58.0622 22.7413 58.1661 23.7322 58.1738 24.7078C58.2046 29.0209 58.1853 33.3341 58.1853 37.7625ZM5.8878 52.4188V87.1123H58.1199V52.4188H5.8878ZM17.4364 46.4733V45.1521C17.4364 38.9032 17.4287 32.6581 17.4441 26.4092C17.4441 25.5067 17.5056 24.5926 17.6519 23.7015C18.7871 16.888 24.5594 11.8605 31.5517 11.5686C38.2707 11.2843 44.5741 15.947 46.0788 22.6146C46.6021 24.9382 46.4367 27.4155 46.5636 29.8275C46.6021 30.5265 46.5675 31.2294 46.5675 31.8938H52.2206C52.2206 29.0632 52.54 26.2594 52.1667 23.5517C50.5042 11.5647 39.4713 3.87169 27.6534 6.27985C19.0064 8.04275 11.9988 16.0354 11.7371 24.8038C11.5293 31.8401 11.664 38.8878 11.6525 45.9279C11.6525 46.1008 11.7179 46.2736 11.7564 46.4695H17.4325L17.4364 46.4733Z" fill="white" />
                <path d="M29.0618 77.1223C29.0618 76.8573 29.0311 76.5885 29.0695 76.3273C29.1888 75.5169 28.9041 75.0752 28.1113 74.695C24.4555 72.9359 22.5237 68.7841 23.4627 64.9318C24.4594 60.8606 28.1267 58.0146 32.252 58.1144C36.1695 58.2105 39.6291 61.0641 40.5565 64.9663C41.4647 68.7917 39.5598 72.909 35.9617 74.6566C35.1421 75.056 34.8496 75.4977 34.9304 76.3657C35.0035 77.1146 34.9766 77.879 34.9304 78.6317C34.8227 80.218 33.572 81.4086 32.0404 81.4355C30.4895 81.4624 29.1734 80.2141 29.0695 78.5895C29.0388 78.1017 29.0657 77.6063 29.0657 77.1146H29.0618V77.1223ZM31.9557 69.7135C33.5912 69.7327 34.8611 68.519 34.8919 66.9059C34.9227 65.3274 33.6412 64.0138 32.0481 63.9908C30.4434 63.9677 29.1388 65.2198 29.108 66.8138C29.0772 68.4077 30.3433 69.6943 31.9557 69.7135Z" fill="white" />
            </g>
            <defs>
                <clipPath id="clip0_18_5586">
                    <rect width="64" height="93" fill="white" />
                </clipPath>
            </defs>
        </svg>

    </SvgIconContainer>
);
