import { PageTitle, Subtitle1 } from "../../../brand/typographies";
import { StorSelfButton } from "../../../components/buttons/StorSelfButton";
import React from "react";
import { FacilityAddress } from "../../../components/FacilityAddress";
import { useStoreSelfNavigation } from "../../../hooks/useStorSelfNavigation";
import useAnalytics from "../../../hooks/useAnalytics";

export const MascotHumph = React.lazy(() => import('../../../components/icons/MascotHumph'));

export const NoneHere = ({ title, subtitle, navigateFunc }: { title: string; subtitle: string; navigateFunc?: () => void; }) => {
    const navigate = useStoreSelfNavigation();
    useAnalytics("NoneHere", true)

    return (
        <>
            <PageTitle>{title}</PageTitle>
            <FacilityAddress />

            <Subtitle1 sx={{ mt: 1, mb: 2 }} center>{subtitle}</Subtitle1>

            <React.Suspense fallback={null}>
                <MascotHumph />
            </React.Suspense>

            <StorSelfButton
                analyticsTag="Back"
                variant="outlined" onClick={() => navigateFunc ? navigateFunc() : navigate(-1)}>Back</StorSelfButton>
        </>
    );
};
